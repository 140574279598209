import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { HashMap, ZefFormBase, ZefFormsModule } from '@zerops/zef';
import { countryMapReducer } from '@vshosting/components';
import { AllowedLocation } from '@vshosting/models';
import { Country } from '@vshosting/models';
import { EditDomainFormState } from './edit-domain-form.model';
import { MatIconModule } from '@angular/material/icon';

@Component({
  standalone: true,
  selector: 'vshcdn-edit-domain-form',
  templateUrl: './edit-domain-form.component.html',
  styleUrls: [ './edit-domain-form.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgFor,
    NgIf,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatChipsModule,
    ZefFormsModule,
    MatIconModule
  ]
})
export class EditDomainFormComponent extends ZefFormBase<EditDomainFormState> {

  countryMap: HashMap<Country> = {};

  @Input()
  locations: AllowedLocation[];

  @Input()
  apiKey: string;

  @Input()
  apiSalt: string;

  @Input()
  remoteLogTypes: string[];

  @Input()
  get countryList() {
    return this.#countryList;
  }
  set countryList(value: Country[]) {
    this.countryMap = value?.reduce(countryMapReducer, {});
    this.#countryList = value;
  }

  @Input()
  activeLangCode: string;

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  translations: any;

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  remoteLogTypesTranslations: any;

  @Output()
  countryItemRemoved = new EventEmitter<string>();

  #countryList: Country[];
}
