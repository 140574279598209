import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { MatCardModule } from '@angular/material/card';
import { TranslocoModule } from '@jsverse/transloco';

import { ZefSnackInput } from '@zerops/zef';

@Component({
  standalone: true,
  selector: 'vshcdn-snack-info',
  templateUrl: './snack-info.feature.html',
  styleUrls: [ './snack-info.feature.scss' ],
  imports: [
    NgIf,
    MatButtonModule,
    MatCardModule,
    TranslocoModule
  ]
})
export class SnackInfoFeature {
  @Input()
  data: ZefSnackInput;

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  instance: MatSnackBarRef<any>;
}
