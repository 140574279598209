import { QueryParamsHandling } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, scan, withLatestFrom } from 'rxjs/operators';
import { SortDirection } from '@angular/material/sort';
import isEqual from 'lodash-es/isEqual';

import { HashMap, NgrxRouterActivatedRouteState, zefGo } from '@zerops/zef';
import { EssearchFilterPayload, EssearchOperatorTypes } from '@vshosting/models';
import { ItemListColumn } from './item-list.model';

export interface ItemListParams {
  page?: number;
  sortDir?: SortDirection;
  sortBy?: string;
  search?: string;
}

export interface ItemListFormattedParams {
  raw: {
    sortBy?: string;
    sortDir?: SortDirection;
    defaultSortBy?: string;
    defaultSortDir?: SortDirection;
    page?: number;
    search?: string;
  },
  api: {
    limit?: number;
    offset?: number;
    sort?: {
      name: string;
      ascending: boolean;
    }[];
    search?: string;
  }
}

export function paramsWithDefaults$(
  routeState$: Observable<NgrxRouterActivatedRouteState<ItemListParams>>,
  defaultSortBy$: Observable<string>,
  defaultSortDir$: Observable<SortDirection>,
  pageSize$: Observable<number>,
  columnConfig: ItemListColumn[]
): Observable<ItemListFormattedParams> {
  const configMap = columnConfig.reduce((obj, itm) => {
    obj[itm.name] = itm;
    return obj;
  }, {} as HashMap<ItemListColumn>);

  return routeState$.pipe(
    scan((acc, curr) => ({ prev: acc.curr, curr }), { prev: null, curr: null }),
    filter(({ prev, curr }) => {
      const isInitialEmission = !prev;
      const isSamePath = prev?.path === curr.path;
      const isDifferentQueryParams = !isEqual(prev?.queryParams, curr.queryParams);

      return isInitialEmission || (isSamePath && isDifferentQueryParams);
    }),
    map(({ curr }) => curr.queryParams),
    withLatestFrom(defaultSortBy$, defaultSortDir$, pageSize$),
    map(([ { page, sortDir, sortBy, search }, defSortBy, defSortDir, pageSize ]) => ({
      raw: {
        page: (page || 1),
        ..._getLocalFormatSort(defSortBy, defSortDir, sortBy, sortDir),
        search
      },
      api: {
        limit: pageSize,
        offset: ((page || 1) - 1) * pageSize,
        sort: _getApiFormatSort(defSortBy, defSortDir, sortBy, sortDir, configMap),
        search
      }
    }))
  );
}

export function listParams$(
  filter$: Observable<EssearchFilterPayload[]>,
  routeParamsWithDefaults$: Observable<ItemListFormattedParams>,
  searchKey: string,
  includeAll = false
) {
  return combineLatest([
    filter$.pipe(debounceTime(50), map((d) => d || [])),
    routeParamsWithDefaults$.pipe(
      map(({ api }) => {
        return {
          query: {
            limit: !includeAll ? api.limit : undefined,
            offset: !includeAll ? api.offset : undefined,
            sort: api.sort,
          },
          apiSearch: api.search ? [{
            name: searchKey,
            operator: EssearchOperatorTypes.Like,
            value: api.search
          }] : []
        };
      })
    ),
  ]).pipe(map(([ search, params ]) => ({
    search,
    query: params.query,
    apiSearch: params.apiSearch
  })));
}

export function exportSettings$(
  filter$: Observable<EssearchFilterPayload[]>,
  routeParamsWithDefaults$: Observable<ItemListFormattedParams>,
  searchKey: string,
  id$?: Observable<string>,
  idKey?: string
) {
  if (!id$) {
    return listParams$(filter$, routeParamsWithDefaults$, searchKey, true);
  }

  return listParams$(filter$, routeParamsWithDefaults$, searchKey, true).pipe(
    withLatestFrom(id$),
    map(([ data, id ]) => ({
      ...data,
      search: [
        {
          name: idKey,
          operator: EssearchOperatorTypes.Eq,
          value: id
        },
        ...data.search
      ]
    }))
  );
}

export function subscribeParams$(
  id$: Observable<string>,
  namespace$: Observable<string>,
  filter$: Observable<EssearchFilterPayload[]>,
  routeParamsWithDefaults$: Observable<ItemListFormattedParams>,
  searchKey: string
) {

  return combineLatest([
    id$.pipe(filter((d) => !!d), distinctUntilChanged()),
    namespace$.pipe(debounceTime(50), distinctUntilChanged()),
    listParams$(
      filter$,
      routeParamsWithDefaults$,
      searchKey
    )
  ]).pipe(
    map(([
      id,
      namespace,
      { search, query, apiSearch }
    ]) => ({ id, namespace, search, query, apiSearch }))
  );
}

export const navigate = (data: {
  params?: ItemListParams;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  path?: any[];
  strategy?: QueryParamsHandling
}) => zefGo(
  data?.path || [],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (data?.params as any) || {},
  { queryParamsHandling: data?.strategy !== undefined ? data?.strategy : 'merge' }
);

export function getExportColumns(columns: ItemListColumn[]): string[] {
  return columns.reduce<string[]>((acc, cur) => {
    if (cur.exportColumns) {
      acc.push(...cur.exportColumns);
    } else if (cur.dataPath) {
      acc.push(cur.dataPath);
    }
    return acc;
  }, []);
}

export const createCustomSorter = (key: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (item: any) => {
    const value = item[key];

    if (value === null) {
      return Number.MIN_SAFE_INTEGER;
    }

    return value;
  };
};

function _getApiFormatSort(
  defaultBy: string,
  defaultDir: SortDirection,
  by: string,
  dir: string,
  colConfig: HashMap<ItemListColumn>
) {
  const config = colConfig[(by || defaultBy)];

  if (config.sort?.length) {
   return config.sort.map((name) => ({
      name,
      ascending: (dir || defaultDir) === 'asc'
    }));
  } else {
    return [{
      name: by || defaultBy,
      ascending: (dir || defaultDir) === 'asc'
    }];
  }

}

function _getLocalFormatSort(
  defaultBy: string,
  defaultDir: SortDirection,
  by: string,
  dir: string
) {

  return {
    sortBy: (by || defaultBy),
    sortDir: (dir || defaultDir) as SortDirection,
    defaultSortBy: ((dir && by) ? by : defaultBy),
    defaultSortDir: ((dir && by) ? dir : defaultDir) as SortDirection
  };

}
