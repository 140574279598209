import { version } from './version';

export const environment = {
  production: true,
  authWebsiteUrl: 'https://admin.vshosting.cloud',
  cdnApiUrl: 'https://cdn.vshosting.cloud/api/public',
  clientZoneApi: 'https://admin.vshosting.cloud/api/public',
  authApiUrl: 'https://admin.vshosting.cloud/api/public/auth',
  websocketAuthUrl: 'https://cdn.vshosting.cloud/api/public/web-socket/login',
  websocketApiPath: 'api/public/web-socket',
  websocketHost: 'cdn.vshosting.cloud',
  cdnHost: 'vshcdn.net',
  maxWarmCacheUrls: '20',
  maxPurgeUrls: '20',
  sentryDsn: 'https://ac47338ecd76450f8ea0fc7664b39e5a@sentry-ng.vshosting.cz/7',
  sentryEnvName: 'production',
  version: version
};
