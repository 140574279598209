<div
  class="__app"
  [class.is-visible]="!state.isAuthChecking && state.authorized">
  <vshcdn-app-bar />
  <router-outlet />
</div>

<vshcdn-pop-error
  *zefPopErrorOutlet="let data = data; let instance = instance;"
  [data]="data"
  [instance]="instance" />

<vshcdn-snack-error
  *zefSnackErrorOutlet="let data = data; let instance = instance;"
  [data]="data"
  [instance]="instance" />

<vshcdn-snack-success
  *zefSnackOutlet="let data = data; let instance = instance; type: 'success'"
  [data]="data"
  [instance]="instance" />

<vshcdn-snack-warning
  *zefSnackOutlet="let data = data; let instance = instance; type: 'warning'"
  [data]="data"
  [instance]="instance" />

<vshcdn-snack-info
  *zefSnackOutlet="let data = data; let instance = instance; type: 'info'"
  [data]="data"
  [instance]="instance" />

<vshcdn-dialog-error
  *zefDialogErrorOutlet="let data = data"
  [data]="data" />

<vshcdn-login />

<vshcdn-add-domain-dialog />

<vshcdn-warm-cache-dialog />

<vshcdn-purge-cache-dialog />

<vshcdn-delete-domain-dialog />

<vshcdn-edit-domain-dialog />

<vshcdn-purge-log-dialog />

<vshcdn-access-log-dialog />

<vshcdn-warm-log-dialog />

<vshcdn-domain-existence-check-dialog />
