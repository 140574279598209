import { Component, inject } from '@angular/core';
import {
  distinctUntilChanged,
  filter,
  map,
  Subject,
  switchMap,
  withLatestFrom
} from 'rxjs';
import { select, Store } from '@ngrx/store';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoService } from '@jsverse/transloco';

import {
  formValueOnValid,
  selectZefDialogState,
  zefDialogClose,
  ZefReactiveComponentBase
} from '@zerops/zef';
import { ZefProgressErrorModule } from '@zerops/zef';
import { urlsCleanup, VshcDialogWrapperComponent } from '@vshosting/components';
import { environment } from '@vshosting/cdn/env';
import { DomainBaseActions, DomainEntity } from '@vshosting/cdn/core';
import { PurgeCacheFormComponent } from './components/purge-cache-form';
import { FEATURE_NAME } from './purge-cache-dialog.constant';
import { PurgeCacheDialogForm } from './purge-cache-dialog.form';

@Component({
  standalone: true,
  selector: 'vshcdn-purge-cache-dialog',
  templateUrl: './purge-cache-dialog.feature.html',
  styleUrls: [ './purge-cache-dialog.feature.scss' ],
  imports: [
    MatButtonModule,
    MatIconModule,
    ZefProgressErrorModule,
    PurgeCacheFormComponent,
    VshcDialogWrapperComponent
  ]
})
export class PurgeCacheDialogFeature extends ZefReactiveComponentBase {

  // # Deps
  #store = inject(Store);
  #translocoService = inject(TranslocoService);
  #domainEntity = inject(DomainEntity);

  // # Forms
  purgeCacheForm = inject(PurgeCacheDialogForm);

  // # Event Streams
  onPurgeCache$ = new Subject<void>();
  onClose$ = new Subject<void>();

  // # Data
  // -- sync
  purgeCacheActionKey = DomainBaseActions.purgeCache.type;

  // -- async
  dialogState$ = this.#store.pipe(select(selectZefDialogState(FEATURE_NAME)));
  open$ = this.dialogState$.pipe(map((d) => d?.state));
  domainId$ = this.dialogState$.pipe(
    map((d) => d?.meta),
    filter((d) => !!d),
    distinctUntilChanged()
  );
  domain$ = this.domainId$.pipe(
    switchMap((id) => this.#domainEntity.entityById$(id)),
    filter((d) => !!d)
  );
  translations$ = this.domain$.pipe(
    filter((d) => !!d),
    switchMap((domain) => this.#translocoService.selectTranslateObject(
    'purgeCacheDialog',
    {
      title: { domain: domain.proxyDomain },
      purgeCacheForm: {
        purgeCacheHint: { max: environment.maxPurgeUrls }
      }
    }
    ))
  );
  formErrorTranslations$ = this.#translocoService.selectTranslateObject(
    'formErrorMessages',
    { urlsInvalidLength: { max: environment.maxPurgeUrls } }
  );

  // # Resolver
  state = this.$connect({
    open: this.open$,
    purgeCacheFormState: this.purgeCacheForm.state$,
    translations: this.translations$,
    formErrorTranslations: this.formErrorTranslations$,
    domain: this.domain$,
  });

  // # Action Streams
  #closeAction$ = this.onClose$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  );
  #purgeCacheAction$ = this.onPurgeCache$.pipe(
    formValueOnValid(this.purgeCacheForm),
    withLatestFrom(this.domainId$),
    map(([ { urls }, id ]) => DomainBaseActions.purgeCache({
      data: {
        id,
        urls: urlsCleanup(urls)
      },
      meta: {
        tag: FEATURE_NAME
      }
    }))
  );

  constructor() {
    super();
    this.$dispatchActions([
      this.#closeAction$,
      this.#purgeCacheAction$
    ]);
  }


}
