import { inject, Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { onZefDialogClose } from '@zerops/zef';
import { FEATURE_NAME } from './access-log-dialog.constant';
import { DomainBaseActions } from '@vshosting/cdn/core';
import { map } from 'rxjs';

@Injectable()
export class AccessLogDialogEffect {
  // # Deps
  #actions$ = inject(Actions);

  onDialogCloseCleanup$ = createEffect(() => this.#actions$.pipe(
    onZefDialogClose(FEATURE_NAME),
    map(() => DomainBaseActions.domainAccessLogReset())
  ));

}
