import { createActionGroup, createFeature, createReducer, on } from '@ngrx/store';

import {
  zefActionFailPayload,
  zefActionPayload,
  zefActionSuccessPayload
} from '@zerops/zef';
import { CdnClientService } from '@vshosting/models';
import { FEATURE_NAME } from './client-service-base.constant';
import { ClientServiceBaseState } from './client-service-base.model';
import {
  LoadClientServiceStatisticsAggregatedPayload,
  LoadClientServiceStatisticsAggregatedResponse,
  LoadClientServiceStatisticsPayload,
  LoadClientServiceStatisticsResponse
} from './client-service-base.model';

export const ClientServiceBaseActions = createActionGroup({
  source: '@vshapps/cdn/core/client-service-base',
  events: {
    'load client service': zefActionPayload<{ id: string; }>(),
    'load client service success': zefActionSuccessPayload<CdnClientService>(),
    'load client service fail': zefActionFailPayload,

    'terminate trial': zefActionPayload<void>(),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    'terminate trial success': zefActionSuccessPayload<any>(),
    'terminate trial fail': zefActionFailPayload,

    'load statistics': zefActionPayload<LoadClientServiceStatisticsPayload>(),
    'load statistics success': zefActionSuccessPayload<LoadClientServiceStatisticsResponse>(),
    'load statistics fail': zefActionFailPayload,

    'load aggregated statistics': zefActionPayload<LoadClientServiceStatisticsAggregatedPayload>(),
    'load aggregated statistics success': zefActionSuccessPayload<LoadClientServiceStatisticsAggregatedResponse>(),
    'load aggregated statistics fail': zefActionFailPayload
  }
});

const initialState = new ClientServiceBaseState();

const handleLoadClientServiceSuccess = (
  state: ClientServiceBaseState,
  { data }: ReturnType<typeof ClientServiceBaseActions.loadClientServiceSuccess>
): ClientServiceBaseState => ({
  ...state,
  data
});

const handleLoadStatisticsSuccess = (
  state: ClientServiceBaseState,
  action: ReturnType<typeof ClientServiceBaseActions.loadStatisticsSuccess>
): ClientServiceBaseState => {
  const { filter } = action.originalAction.data;
  return {
    ...state,
    statistics: {
      ...state.statistics,
      [filter]: action.data.data
    }
  };
};

const handleLoadStatisticsAggregatedSuccess = (
  state: ClientServiceBaseState,
  { data }: ReturnType<typeof ClientServiceBaseActions.loadAggregatedStatisticsSuccess>
): ClientServiceBaseState => {
  return {
    ...state,
    aggregatedStatistics: data
  };
};

export const clientServiceBaseState = createFeature({
  name: FEATURE_NAME,
  reducer: createReducer(
    initialState,
    on(ClientServiceBaseActions.loadClientServiceSuccess, handleLoadClientServiceSuccess),
    on(ClientServiceBaseActions.loadStatisticsSuccess, handleLoadStatisticsSuccess),
    on(ClientServiceBaseActions.loadAggregatedStatisticsSuccess, handleLoadStatisticsAggregatedSuccess)
  )
});
