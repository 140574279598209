import { inject, Injectable } from '@angular/core';
import {
  delay,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap
} from 'rxjs';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect } from '@ngrx/effects';
import {
  onWebsocketMessageDispatchAddRemoveEntities,
  onZefDialogClose,
  successOf,
  zefDialogClose,
  zefDialogOpen
} from '@zerops/zef';
import { UserEntity, AliasEntity, ALIAS_BASE_FEATURE_NAME } from '@vshosting/cdn/core';
import { FEATURE_NAME } from './aliases.constant';
import { AliasesForm } from './aliases.form';
import { selectActiveAliasFeatureId } from './aliases.selector';

@Injectable()
export class AliasesEffect {

  // # Deps
  #actions$ = inject(Actions);
  #aliasEntity = inject(AliasEntity);
  #store = inject(Store);
  #userEntity = inject(UserEntity);
  #form = inject(AliasesForm);

  // # Streams
  #activeId$ = this.#store.pipe(select(selectActiveAliasFeatureId));

  #onAddAliasDialogOpen$ = this.#actions$.pipe(
    filter(({ type, key }) => type === zefDialogOpen.type && key === FEATURE_NAME)
  );

  #onAddAliasDialogSuccess$ = this.#actions$.pipe(
    successOf(this.#aliasEntity.addOne),
    filter(({ originalAction }) => originalAction.meta?.tag === FEATURE_NAME)
  );

  // # Effects
  // -- entity lists / updates
  setupDomainAliasStream$ = createEffect(() => this.#activeId$.pipe(
    filter((d) => !!d),
    distinctUntilChanged(),
    switchMap((id) => this.#userEntity.activeClientServiceId$.pipe(
      map((clientServiceId) => this.#aliasEntity.listSubscribe(
        { clientId: clientServiceId, name: 'clientServiceId' },
        { id, name: ALIAS_BASE_FEATURE_NAME },
        {
          search: [
            {
              name: 'domainId',
              operator: 'eq',
              value: id
            }
          ],
          sort: [
            {
              name: 'created',
              ascending: false
            }
          ]
        }
      ))
    ))
  ));

  setupAliasListChangeStream$ = createEffect(() => this.#actions$.pipe(
    onWebsocketMessageDispatchAddRemoveEntities(
      this.#aliasEntity,
      ALIAS_BASE_FEATURE_NAME,
      undefined,
      this.#store.pipe(select(selectActiveAliasFeatureId))
    )
  ));

  onAliasAddDialoOpenFocusDomainName$ = createEffect(() => this.#onAddAliasDialogOpen$.pipe(
    delay(150),
    map(() => this.#form.focus('aliasDomain'))
  ));

  onAddAliasUpdateSuccessCloseDialog$ = createEffect(() => this.#onAddAliasDialogSuccess$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  ));

  onAddAliasDialogCloseCleanup$$ = this.#store.pipe(
    onZefDialogClose(FEATURE_NAME),
    mergeMap(() => [
      this.#form.reset(),
      this.#form.setDefaultValues()
    ])
  );

  onDialogCloseCleanup$ = createEffect(() => this.#actions$.pipe(
    onZefDialogClose(FEATURE_NAME),
    delay(150),
    mergeMap(() => ([
      this.#form.reset(),
      this.#form.setDefaultValues()
    ]))
  ));

}
