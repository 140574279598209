import { inject, Injectable } from '@angular/core';
import {
  catchError,
  map,
  of,
  switchMap,
  withLatestFrom
} from 'rxjs';
import {
  Actions,
  createEffect,
  ofType
} from '@ngrx/effects';

import { UserEntity } from '@vshosting/cdn/core';
import { ClientServiceBaseActions } from './client-service-base.state';
import { ClientServiceBaseApi } from './client-service-base.api';

@Injectable()
export class ClientServiceBaseEffect {

  // # Deps
  #actions$ = inject(Actions);
  #userEntity = inject(UserEntity);
  #clientServiceBaseApi = inject(ClientServiceBaseApi);

  // # Effects
  onLoadClientService$ = createEffect(() => this.#actions$.pipe(
    ofType(ClientServiceBaseActions.loadClientService),
    switchMap((action) => this.#clientServiceBaseApi
      .loadClientService$(action.data.id)
      .pipe(
        map((data) => ClientServiceBaseActions.loadClientServiceSuccess(data, action)),
        catchError((err) => of(ClientServiceBaseActions.loadClientServiceFail(err, action)))
      )
    )
  ));

  onTerminateTrial$ = createEffect(() => this.#actions$.pipe(
    ofType(ClientServiceBaseActions.terminateTrial),
    withLatestFrom(this.#userEntity.activeClientServiceId$),
    switchMap(([ action, clientServiceId ]) => this.#clientServiceBaseApi
      .terminateTrial$(clientServiceId)
      .pipe(
        map((data) => ClientServiceBaseActions.terminateTrialSuccess(data, action)),
        catchError((err) => of(ClientServiceBaseActions.terminateTrialFail(err, action)))
      )
    )
  ));

  onLoadStatistics$ = createEffect(() => this.#actions$.pipe(
    ofType(ClientServiceBaseActions.loadStatistics),
    switchMap((action) => this.#clientServiceBaseApi
      .loadStatistics$(action.data)
      .pipe(
        map((data) => ClientServiceBaseActions.loadStatisticsSuccess(data, action)),
        catchError((err) => of(ClientServiceBaseActions.loadStatisticsFail(err, action)))
      )
    )
  ));

  onLoadAggregatedStatistics$ = createEffect(() => this.#actions$.pipe(
    ofType(ClientServiceBaseActions.loadAggregatedStatistics),
    switchMap((action) => this.#clientServiceBaseApi
      .loadAggregatedStatistics$(action.data)
      .pipe(
        map((data) => ClientServiceBaseActions.loadAggregatedStatisticsSuccess(data, action)),
        catchError((err) => of(ClientServiceBaseActions.loadAggregatedStatisticsSuccess(err, action)))
      )
    )
  ));

}
