import { inject, Injectable } from '@angular/core';
import {
  delay,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  withLatestFrom
} from 'rxjs';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect } from '@ngrx/effects';
import { box } from 'ngrx-forms';
import { TranslocoService } from '@jsverse/transloco';
import {
  onZefDialogClose,
  selectZefDialogState,
  successOf,
  zefDialogClose,
  ZefSnackService
} from '@zerops/zef';
import { DomainEntity } from '@vshosting/cdn/core';
import { FEATURE_NAME } from './edit-domain-dialog.constant';
import { EditDomainDialogForm } from './edit-domain-dialog.form';

@Injectable()
export class EditDomainDialogEffect {

  // # Deps
  #store = inject(Store);
  #actions$ = inject(Actions);
  #domainEntity = inject(DomainEntity);
  #form = inject(EditDomainDialogForm);
  #snackService = inject(ZefSnackService);
  #translocoService = inject(TranslocoService);

  // # Data
  // -- async
  translations$ = this.#translocoService.selectTranslateObject('editDomainDialog');

  // # Streams
  #domainEditDialogOpenId$ = this.#store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    filter((d) => !!d),
    map((s) => s.meta),
    filter((s) => !!s)
  );
  #domainUpdateSuccess$ = this.#actions$.pipe(
    successOf(this.#domainEntity.updateOne),
    filter(({ originalAction }) => originalAction?.meta?.tag === FEATURE_NAME)
  );

  // # Effects
  onDomainEditDialogOpenSetFormValues$ = createEffect(() => this.#domainEditDialogOpenId$.pipe(
    switchMap((id) => this.#domainEntity.entityById$(id).pipe(
      filter((d) => !!d),
      take(1),
      map((d) => this.#form.setValue({
        backendDomain: d.domainName,
        forceCache: d.forceCache,
        checkBackendCert: d.checkBackendCertificate,
        locations: box(d.locations?.map(({ id }) => id) || []),
        webp: d.webp,
        forceCacheTime: d.forceCacheTime,
        geoIpMode: d.geoIpMode,
        geoIpCountries: box(d.geoIpCountries),
        enableGeoIp: !!d.geoIpCountries,
        enableRemoteLog: !!d.remoteLogDestination,
        remoteLogDestination: d.remoteLogDestination,
        remoteLogType: d.remoteLogType
      }))
    ))
  ));

  onDomainUpdateSuccessCloseDialog$ = createEffect(() => this.#domainUpdateSuccess$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  ));

  onDomainUpdateSuccessNotification$ = createEffect(() => this.#domainUpdateSuccess$.pipe(
    withLatestFrom(this.translations$),
    switchMap(([ _, translation ]) => this.#snackService.success$({ text: translation.domainEdited }))
  ), { dispatch: false });

  onDomainUpdateDialogCloseCleanup$ = createEffect(() => this.#actions$.pipe(
    onZefDialogClose(FEATURE_NAME),
    delay(150),
    mergeMap(() => ([
      this.#form.reset(),
      this.#form.setDefaultValues()
    ]))
  ));

}
