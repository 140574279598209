import { Component, inject } from '@angular/core';
import { NgFor } from '@angular/common';
import {
  distinctUntilChanged,
  filter,
  map,
  Subject,
  switchMap,
} from 'rxjs';
import { select, Store } from '@ngrx/store';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { TranslocoService } from '@jsverse/transloco';
import { TranslocoLocaleModule } from '@jsverse/transloco-locale';

import {
  ZefReactiveComponentBase,
  selectZefDialogState,
  zefDialogClose,
  ZefAvatarModule,
  ZefProgressErrorModule
} from '@zerops/zef';
import { TASK_QUEUE_STATUS_COLOR_MAP } from '@vshosting/models';
import { StatusIconComponent, VshcDialogWrapperComponent } from '@vshosting/components';
import {
  DomainEntity,
  NodeTaskQueueConsolidatedEntity
} from '@vshosting/cdn/core';
import { FEATURE_NAME } from './purge-log-dialog.constant';

@Component({
  standalone: true,
  selector: 'vshcdn-purge-log-dialog',
  templateUrl: './purge-log-dialog.feature.html',
  styleUrls: [ './purge-log-dialog.feature.scss' ],
  imports: [
    NgFor,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    TranslocoLocaleModule,
    ZefProgressErrorModule,
    ZefAvatarModule,
    StatusIconComponent,
    VshcDialogWrapperComponent
  ]
})
export class PurgeLogDialogFeature extends ZefReactiveComponentBase {

  // # Deps
  #store = inject(Store);
  #nodeTaskQueueConsolidatedEntity = inject(NodeTaskQueueConsolidatedEntity);
  #translocoService = inject(TranslocoService);
  #domainEntity = inject(DomainEntity);
  taskQueueStatusColorMap = TASK_QUEUE_STATUS_COLOR_MAP;

  // # Event streams
  onClose$ = new Subject<void>();

  // # Data
  // -- sync
  columnsToDisplay = [
    'status',
    'taskStarted',
    'taskFinished',
    'userFullName',
    'configData'
  ];

  // -- async
  state$ = this.#store.pipe(select(selectZefDialogState(FEATURE_NAME)));
  open$ = this.state$.pipe(map((d) => d?.state));
  id$ = this.state$.pipe(map((d) => d?.meta));
  purgeLog$ = this.id$.pipe(
    filter((d) => !!d),
    distinctUntilChanged(),
    switchMap((id) => this.#nodeTaskQueueConsolidatedEntity.list$({
      name: FEATURE_NAME,
      id
    }))
  );
  domainId$ = this.state$.pipe(
    map((d) => d?.meta),
    filter((d) => !!d),
    distinctUntilChanged()
  );
  domain$ = this.domainId$.pipe(
    switchMap((id) => this.#domainEntity.entityById$(id)),
    filter((d) => !!d)
  );
  translations$ = this.domain$.pipe(
    filter((d) => !!d),
    switchMap((domain) => this.#translocoService.selectTranslateObject(
      'purgeLogDialog',
      {
        title: { domain: domain.proxyDomain }
      }
    ))
  );
  generalTranslations$ = this.#translocoService.selectTranslateObject('general');

  // # Resolver
  state = this.$connect({
    open: this.open$,
    purgeLog: this.purgeLog$,
    translations: this.translations$,
    generalTranslations: this.generalTranslations$
  });

  // # Action streams
  #closeAction$ = this.onClose$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  );

  constructor() {
    super();

    // # Dispatcher
    this.$dispatchActions([ this.#closeAction$ ]);
  }

}
