import { inject, Injectable } from '@angular/core';
import { map } from 'rxjs';
import { createEffect } from '@ngrx/effects';
import { endOfMonth, startOfMonth } from 'date-fns/esm';
import { DEFAULT_CLIENT_SERVICE_STATISTICS_RANGE } from '@vshosting/cdn/app';
import { ClientServiceBaseActions, UserEntity } from '@vshosting/cdn/core';

@Injectable()
export class DashboardEffect {
  // # Deps
  #userEntity = inject(UserEntity);

  // # Effects
  onClientIdReadyLoadClientService$ = createEffect(() => this.#userEntity.activeClientServiceId$.pipe(
    map((id) => ClientServiceBaseActions.loadClientService({ id }))
  ));

  onClientServiceIdLoadClientServiceStatistics$ = createEffect(() => this.#userEntity.activeClientServiceId$.pipe(
    map((id) => ClientServiceBaseActions.loadStatistics({
      id,
      filter: DEFAULT_CLIENT_SERVICE_STATISTICS_RANGE
    }))
  ));

  onClientServiceIdLoadClientServiceStatisticsAggregated$ = createEffect(() => this.#userEntity.activeClientServiceId$.pipe(
    map((id) => ClientServiceBaseActions.loadAggregatedStatistics({
      id,
      dateFrom: startOfMonth(new Date()).toISOString(),
      dateTo: endOfMonth(new Date()).toISOString()
    }))
  ));

}
