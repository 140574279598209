import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { ChartConfiguration } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { cs, enUS } from 'date-fns/locale';
import { TranslocoLocaleModule } from '@jsverse/transloco-locale';

import { SatPopoverModule, ZefScrollModule } from '@zerops/zef';
import { CdnDomainStatistic } from '@vshosting/models';
import {
  FormatBytesPipe,
  formatTooltipTitleDates,
  formatBytes,
  DynamicPopAnchorDirective
} from '@vshosting/components';
import { StatisticsFilters } from '@vshosting/cdn/app';

enum ChartTypes {
  HIT_MISS = 'HIT_MISS',
  TRAFFIC = 'TRAFFIC'
}

enum ActiveTypes {
  GRAPH = 'GRAPH',
  TABLE = 'TABLE'
}

interface ChartConfig {
  options: ChartConfiguration<'bar'>['options'];
  filters: StatisticsFilters[];
}

interface ChartsConfigs {
  [key: string]: ChartConfig;
}

const chartsConfigCreator = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  translations: any,
  data: CdnDomainStatistic[],
  locale: Locale
): ChartsConfigs  => {
  const filters = Object.values(StatisticsFilters);

  return {
    [ChartTypes.HIT_MISS]: {
      options: {
        responsive: true,
        // aspectRatio: 16/5,
        locale: 'cs-CZ',
        maintainAspectRatio: false,
        animation: {
          duration: 0
        },
        plugins: {
          tooltip: {
            mode: 'index',
            intersect: false,
            itemSort: (a, b) => {
              return b.datasetIndex - a.datasetIndex;
            },
            callbacks: {
              title: (d) => {
                return formatTooltipTitleDates(
                  data[d[0].dataIndex].dateFrom,
                  data[d[0].dataIndex].dateTill,
                  locale
                );
              },
              label: (d) => {
                return `${translations?.enums[d.dataset.label]}: ${d.raw}x`;
              }
            }
          }
        },
        scales: {
          x: {
            type: 'timeseries',
            ticks: {
              align: 'start',
            },
            adapters: {
              date: {
                locale
              }
            },
            stacked: true,
            grid: {
              display: false
            }
          },
          y: {
            type: 'linear',
            stacked: true,
            beginAtZero: true,
            grid: {
              display: false
            }
          }
        }
      },
      filters: filters.filter((d) => d !== 'X_DAYS_FROM')
    },
    [ChartTypes.TRAFFIC]: {
      options: {
        responsive: true,
        locale: 'cs-CZ',
        // aspectRatio: 16/5,
        maintainAspectRatio: false,
        animation: {
          duration: 0
        },
        plugins: {
          tooltip: {
            mode: 'index',
            intersect: false,
            itemSort: (a, b) => {
              return b.datasetIndex - a.datasetIndex;
            },
            callbacks: {
              title: (d) => {
                return formatTooltipTitleDates(
                  data[d[0].dataIndex].dateFrom,
                  data[d[0].dataIndex].dateTill,
                  locale
                );
              },
              label: (d) => {
                return `${translations?.enums[d?.dataset.label]}: ${formatBytes(d?.raw as number)}`;
              }
            }
          }
        },
        scales: {
          x: {
            type: 'timeseries',
            ticks: {
              align: 'start',
            },
            adapters: {
              date: {
                locale
              }
            },
            grid: {
              display: false
            }
          },
          y: {
            type: 'linear',
            beginAtZero: true,
            grid: {
              display: false
            },
            ticks: {
              callback: (value) => {
                return formatBytes(value as number);
              }
            }
          }
        }
      },
      filters: filters.filter((d) => d !== 'X_DAYS_FROM')
    }
  };

}

@Component({
  standalone: true,
  selector: 'vshcdn-domain-statistics-graph-table',
  templateUrl: './domain-statistics-graph-table.component.html',
  styleUrls: [ './domain-statistics-graph-table.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgFor,
    NgIf,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatCardModule,
    MatTableModule,
    TranslocoLocaleModule,
    ZefScrollModule,
    BaseChartDirective,
    SatPopoverModule,
    DynamicPopAnchorDirective,
    FormatBytesPipe
  ]
})
export class DomainStatisticsGraphTableComponent implements OnChanges {

  @Input()
  data: CdnDomainStatistic[];

  @Input()
  activeFilter: StatisticsFilters;

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  translations: any;

  @Input()
  locale: string;

  @Output()
  filterChange = new EventEmitter<StatisticsFilters>();

  activeChart = ChartTypes.HIT_MISS;
  chartTypes = ChartTypes;
  chartTypesArr = Object.values(ChartTypes);
  chartOptions: ChartsConfigs;
  hitMissChartData: ChartConfiguration<'bar'>['data'];
  trafficChartData: ChartConfiguration<'bar'>['data'];
  activeType = ActiveTypes.GRAPH;
  activeTypes = ActiveTypes;
  iconMap = {
    [ChartTypes.HIT_MISS]: 'track_changes',
    [ChartTypes.TRAFFIC]: 'public',
    [ActiveTypes.GRAPH]: 'bar_chart',
    [ActiveTypes.TABLE]: 'list',
  };
  activeTypesArr = Object.values(ActiveTypes);
  sumData: Record<string, number>;
  columnsToDisplay = [
    'date',
    'traffic',
    'hits',
    'miss',
    'ratio'
  ];

  ngOnChanges() {
    if (this.data?.length && !!this.translations) {

      const locale = this.locale === 'cs' ? cs : enUS;

      this.chartOptions = chartsConfigCreator(
        this.translations,
        this.data,
        locale
      );

      this.sumData = this.data.reduce((acc, d) => {
        acc.HITS = acc.HITS + parseFloat(d.hits.toFixed(0));
        acc.MISSES = acc.MISSES + parseFloat(d.miss.toFixed(0));
        acc.TRAFFIC = acc.TRAFFIC + d.traffic;
        return acc;
      }, {
        HITS: 0,
        MISSES: 0,
        TRAFFIC: 0
      });

      this.hitMissChartData = {
        labels: this.data.map((d) => `${d.dateFrom}`),
        datasets: [
          {
            data: this.data.map((d) => d.hits),
            label: 'HITS',
            backgroundColor: '#00CC55',
            borderColor: '#00CC55'
          },
          {
            data: this.data.map((d) => d.miss),
            label: 'MISSES',
            backgroundColor: '#f4b32d',
            borderColor: '#f4b32d'
          }
        ]
      };

      this.trafficChartData = {
        labels: this.data.map((d) => `${d.dateFrom}`),
        datasets: [
          {
            data: this.data.map((d) => d.traffic),
            label: 'TRAFFIC',
            backgroundColor: '#2196f3',
            borderColor: '#2196f3'
          }
        ]
      };

    }
  }

}
