<vshc-dialog-wrapper
  [open]="state.open"
  [heading]="state.translations?.title"
  (closeTrigger)="onClose$.next()">
  @if (state.generalTranslations && state.translations) {
    <div class="__wrap">

      <div class="fx:grid  fx:grid-cols-3  fx:align-items-center  fx:pb-1">

        <div class="fx:text-left">
          <button
            (click)="selectedDate$.next(state.nextPrevDates?.prev)"
            [disabled]="!state.nextPrevDates?.prev"
            mat-flat-button>
            <mat-icon>navigate_before</mat-icon> {{ state.generalTranslations.prevMonth }}
          </button>
        </div>

        <div class="fx:text-center">
          <h3>{{ state.activateDate | translocoDate: { month: 'numeric', year: 'numeric' } }}</h3>
        </div>

        <div class="fx:text-right">
          <button
            (click)="selectedDate$.next(state.nextPrevDates?.next)"
            [disabled]="!state.nextPrevDates?.next"
            mat-flat-button>
            {{ state.generalTranslations.nextMonth }} <mat-icon iconPositionEnd>navigate_next</mat-icon>
          </button>
        </div>

      </div>

      <div
        class="__table-wrap"
        [class.is-loading]="state.isLoading">

        @if (state.isLoading) {
          <mat-progress-spinner
            diameter="30"
            mode="indeterminate"
            class="__spinner"
          />
        }

        <table
          mat-table
          class="__table"
          [dataSource]="state.accessLog">

          <ng-container matColumnDef="date">

            <th
              mat-header-cell
              *matHeaderCellDef>
              {{ state.translations?.accessLogTable.date }}
            </th>

            <td
              mat-cell
              *matCellDef="let item">
              {{ item.date | translocoDate: { dateStyle: 'medium' } }}
            </td>

          </ng-container>

          <ng-container matColumnDef="goAccess">

            <th
              mat-header-cell
              *matHeaderCellDef>
              <div class="fx:pr-0-5  fx:text-right">{{ state.translations?.accessLogTable.goAccess }}</div>
            </th>

            <td
              mat-cell
              *matCellDef="let item">
              <div class="fx:text-right">
                @if (item.goAccess?.link) {

                  <a
                    class="__log-button"
                    mat-button
                    target="_blank"
                    [href]="item.goAccess.linkHtml">
                    {{ state.generalTranslations.open }}
                  </a>

                  <!-- <a
                    class="__log-button"
                    mat-button
                    target="_blank"
                    [href]="item.goAccess.link">
                    {{ state.generalTranslations.download }}
                  </a> -->

                } @else {
                  <mat-icon>remove</mat-icon>
                }
              </div>
            </td>

          </ng-container>

          <ng-container matColumnDef="access">

            <th
              mat-header-cell
              *matHeaderCellDef>
              <div class="fx:pr-0-5  fx:text-right">{{ state.translations?.accessLogTable.access }}</div>
            </th>

            <td
              mat-cell
              *matCellDef="let item">
              <div class="fx:text-right">
                @if (item.access?.link) {
<!--
                  <a
                    class="__log-button"
                    mat-button
                    target="_blank"
                    [href]="item.access.linkHtml">
                    {{ state.generalTranslations.open }}
                  </a> -->

                  <a
                    class="__log-button"
                    mat-button
                    target="_blank"
                    [href]="item.access.link">
                    {{ state.generalTranslations.download }}
                  </a>

                } @else {
                  <mat-icon>remove</mat-icon>
                }
              </div>
            </td>

          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="columnsToDisplay">
          </tr>

          <tr
            mat-row
            *matRowDef="let myRowData; columns: columnsToDisplay">
          </tr>

        </table>

      </div>

    </div>
  }
</vshc-dialog-wrapper>
