import { Pipe, PipeTransform } from '@angular/core';

import { getPropertyByStringPath } from '../table';

@Pipe({
  standalone: true,
  name: 'getPropByString'
})
export class GetPropertyByString implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(object: any, path: string): any {
    return getPropertyByStringPath(object, path);
  }
}
