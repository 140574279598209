import { inject, Injectable } from '@angular/core';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  mergeMap,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs';
import { select, Store } from '@ngrx/store';
import { getCurrentScope } from '@sentry/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslocoService } from '@jsverse/transloco';

import {
  zefCheckStoredTokenSuccess,
  zefLogout,
  zefRefreshTokenFail,
  zefSelectAuthAccessToken,
  zefSetToken,
  zefWebsocketAuth,
  zefWebsocketClosed,
  ZefWebsocketService,
  zefWebsocketTerminate
} from '@zerops/zef';
import { userBaseActions, UserEntity, SettingsBaseActions } from '@vshosting/cdn/core';

@Injectable()
export class AppEffect {

  // # Deps
  #actions$ = inject(Actions);
  #wsService = inject(ZefWebsocketService);
  #store = inject(Store);
  #userEntity = inject(UserEntity);
  #translocoService = inject(TranslocoService);

  // # Streams
  #onAnyAuthSuccess$ = this.#actions$.pipe(ofType(
    zefSetToken,
    userBaseActions.loadStoredUserDataSuccess
  ));

  #onZefSetToken$ = this.#actions$.pipe(ofType(zefSetToken));

  #onZefCheckStoredTokenSuccess$ = this.#actions$.pipe(ofType(zefCheckStoredTokenSuccess));

  #onAnyAuthClear$ = this.#actions$.pipe(ofType(zefLogout));

  // # Effects
  clearAuthOnRefreshTokenFail$ = createEffect(() => this.#actions$.pipe(
    ofType(zefRefreshTokenFail),
    map(() => zefLogout())
  ));

  onCheckStoredTokenSuccessSetUserId$ = createEffect(() => this.#onZefCheckStoredTokenSuccess$.pipe(
    map(({ userId }) => userBaseActions.setUserId(userId)),
  ));

  onCheckStoredTokenSuccess$ = createEffect(() => this.#onZefCheckStoredTokenSuccess$.pipe(
    // needed to allow forFeature user base effect to register
    delay(0),
    mergeMap(() => [
      userBaseActions.loadUser(),
      userBaseActions.loadStoredUserData()
    ])
  ));

  onSetTokenLoadUser$ = createEffect(() => this.#onZefSetToken$.pipe(
    map(({ meta }) => userBaseActions.loadUser({ meta }))
  ));

  onLogoutClearUser$ = createEffect(() => this.#onAnyAuthClear$.pipe(
    map(() => userBaseActions.clearUserData())
  ));

  onAnyAuthSuccessGenerateReceiverId$ = createEffect(() => this.#onZefSetToken$.pipe(
    tap(() => this.#wsService.generateReceiverId())
  ), { dispatch: false });

  onLogoutWebsocketTerminate$ = createEffect(() => this.#onAnyAuthClear$.pipe(
    map(() => zefWebsocketTerminate())
  ));

  onAnyAuthSuccessLoadSettings$ = createEffect(() => this.#onAnyAuthSuccess$.pipe(
    // needed to allow forFeature websocket effect to register
    delay(0),
    map(() =>  SettingsBaseActions.loadSettings())
  ));

  // websockets auth triggers
  onZefSetTokenAuthWebsockets$ = createEffect(() => this.#onZefSetToken$.pipe(
    map(({ data: { accessToken }}) => zefWebsocketAuth({
      token: accessToken,
      receiverId: this.#wsService.getReceiverId()
    }, { type: 'noop' }))
  ));

  onZefCheckStoredSuccessAuthWebsockets$ = createEffect(() => this.#onZefCheckStoredTokenSuccess$.pipe(
    // needed to allow forFeature websocket effect to register
    delay(0),
    map(({ accessToken }) => zefWebsocketAuth({ token: accessToken, receiverId: this.#wsService.getReceiverId() }, { type: 'noop' }))
  ));

  onZefWsClosed$ = createEffect(() => this.#actions$.pipe(
    ofType(zefWebsocketClosed),
    takeUntil(this.#onAnyAuthClear$),
    debounceTime(30000),
    withLatestFrom(this.#store.pipe(select(zefSelectAuthAccessToken))),
    filter(([ _, token ]) => !!token),
    map(([ _, token ]) => zefWebsocketAuth({ token, receiverId: this.#wsService.getReceiverId() }, { type: 'noop' }))
  ));

  onClientUserAvailableSetLanguage$ = createEffect(() => this.#userEntity.activeClientUser$.pipe(
    map((d) => d?.language.id),
    filter((d) => !!d),
    distinctUntilChanged(),
    tap((d) => this.#translocoService.setActiveLang(d))
  ), { dispatch: false });

  onClientUserAvailableSetSentryScope$ = createEffect(() => this.#userEntity.activeClientUser$.pipe(
    filter((d) => !!d?.id),
    distinctUntilKeyChanged('id'),
    tap((d) => getCurrentScope().setUser({
      email: d.email,
      id: d.userId
    }))
  ), { dispatch: false });

}
