import { createActionGroup, createFeature, createReducer, emptyProps, on } from '@ngrx/store';
import {
  zefActionFailPayload,
  zefActionPayload,
  zefActionSuccessPayload
} from '@zerops/zef';
import { FEATURE_NAME } from './domain-base.constant';
import {
  DomainBaseState,
  DomainLogPayload
} from './domain-base.model';
import {
  LoadDomainStatisticsPayload,
  LoadDomainStatisticsResponse,
  PurgeCachePayload,
  PurgeCacheResponse,
  WarmCachePayload,
  WarmCacheResponse
} from './domain-base.model';
import { CdnDomainAccessLog } from '@vshosting/models';
import { groupDomainAccessLogs } from './domain-base.utils';

export const DomainBaseActions = createActionGroup({
  source: '@vshapps/cdn/core/domain-base',
  events: {
    'warm cache': zefActionPayload<WarmCachePayload>(),
    'warm cache success': zefActionSuccessPayload<WarmCacheResponse>(),
    'warm cache fail': zefActionFailPayload,

    'purge cache': zefActionPayload<PurgeCachePayload>(),
    'purge cache success': zefActionSuccessPayload<PurgeCacheResponse>(),
    'purge cache fail': zefActionFailPayload,

    'load statistics': zefActionPayload<LoadDomainStatisticsPayload>(),
    'load statistics success': zefActionSuccessPayload<LoadDomainStatisticsResponse>(),
    'load statistics fail': zefActionFailPayload,

    'activate': zefActionPayload<string>(),
    'activate success': zefActionSuccessPayload<{ success: boolean; }>(),
    'activate fail': zefActionFailPayload,

    'deactivate': zefActionPayload<string>(),
    'deactivate success': zefActionSuccessPayload<{ success: boolean; }>(),
    'deactivate fail': zefActionFailPayload,

    'alias dns check': zefActionPayload<string>(),
    'alias dns check success': zefActionSuccessPayload<{ success: boolean; }>(),
    'alias dns check fail': zefActionFailPayload,

    'domain access log': zefActionPayload<DomainLogPayload>(),
    'domain access log success': zefActionSuccessPayload<CdnDomainAccessLog[]>(),
    'domain access log fail': zefActionFailPayload,
    'domain access log reset': emptyProps()
  }
});

const initialState: DomainBaseState = {
  aliases: undefined,
  domainAccessLogs: undefined,
  statistics: undefined
};

const handleLoadStatisticsSuccess = (
  state: DomainBaseState,
  action: ReturnType<typeof DomainBaseActions.loadStatisticsSuccess>
): DomainBaseState => {
  const { id, filter } = action.originalAction.data;
  return {
    ...state,
    statistics: {
      ...state.statistics,
      [id]: {
        ...(state.statistics?.[id] || {}),
        [filter]: action.data.data
      }
    }
  };
};

const handleDomainAccessLogSuccess = (
  state: DomainBaseState,
  action: ReturnType<typeof DomainBaseActions.domainAccessLogSuccess>
): DomainBaseState => {
  return {
    ...state,
    domainAccessLogs: groupDomainAccessLogs(action.data)
  };
}

const handleDomainAccessLogReset = (
  state: DomainBaseState
): DomainBaseState => {
  return {
    ...state,
    domainAccessLogs: undefined
  };
}

export const domainBaseState = createFeature({
  name: FEATURE_NAME,
  reducer: createReducer(
    initialState,
    on(DomainBaseActions.loadStatisticsSuccess, handleLoadStatisticsSuccess),
    on(DomainBaseActions.domainAccessLogSuccess, handleDomainAccessLogSuccess),
    on(DomainBaseActions.domainAccessLogReset, handleDomainAccessLogReset)
  )
});
