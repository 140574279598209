import { CdnDomainAccessLog } from '@vshosting/models';
import { DomainAccessLogCombined } from './domain-base.model';

export const groupDomainAccessLogs = (logs: CdnDomainAccessLog[]): DomainAccessLogCombined[] => {
  const groupedByDate = new Map<string, Partial<Record<'ACCESS' | 'GOACCESS', {
    link: string;
    linkHtml: string;
  }>>>();

  logs.forEach(log => {
    const existing = groupedByDate.get(log.date) || {};
    groupedByDate.set(log.date, {
      ...existing,
      [log.type]: {
        link: log.link,
        linkHtml: log.linkHtml
      }
    });
  });

  return Array.from(groupedByDate.entries())
    .map(([ date, links ]) => ({
      date,
      access: links.ACCESS,
      goAccess: links.GOACCESS
    }));
};
